import React from "react";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "./styles.css";

import first from './img/1.jpg';
import second from './img/2.jpg';
import third from './img/3.jpg';
import forth from './img/4.jpg';
import fifth from './img/5.jpg';
import sixth from './img/6.jpg';
import seventh from './img/7.jpg';
import eight from './img/8.jpg';
import ninth from './img/9.jpg';
import tenth from './img/10.jpg';
import eleventh from './img/11.jpg';
import twelve from './img/12.jpg';


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 }
];


function Carousel2() {
  return (
    <div className="App">
      <Carousel breakPoints={breakPoints}>
   <Item><img src={first}></img></Item>
   <Item><img src={second}></img></Item>
   <Item><img src={third}></img></Item>
   <Item><img src={forth}></img></Item>
   <Item><img src={fifth}></img></Item>
   <Item><img src={sixth}></img></Item>
   <Item><img src={eight}></img></Item>
   <Item><img src={ninth}></img></Item>

      </Carousel>
    </div>
  );
}

export default Carousel2;