import './App.css';
import Header from './components/Header/Header'
import Navigation from './components/Navigation/Navigation'
import {Route, Routes} from 'react-router-dom'
import Main from './components/Main/Main'
import Requisites from './components/Requisites/Requisites'
import AboutUs from './components/AboutUs/AboutUs'
import Cooperation from './components/Cooperation/Cooperation'
import Team from './components/Team/Team'
import Documents from './components/Documents/Documents'

function App() {
  return (
    <div className="App">
      <div className="Header">
        <Header/>
       </div> 
       <Navigation />
       
       
      
        <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Requisites" element={<Requisites />} />
        <Route path="/" element={<Main />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Cooperation" element={<Cooperation />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Documents" element={<Documents />} />
       
        </Routes> 
       
        
    </div>
  );
}

export default App;
