import React from 'react'
import banner from './banner.jpg';
import vol from './vol.jpg';
import './Cooperation.css';


function Cooperation() {
    return (
      
        <div className='aboutUsMain'>
          <div className='Big_text Banner'> Сотрудничество</div>
          <div className='Banner'>
          <img src={banner} alt="banner"></img></div>
        <div>
         Мы постоянно находимся в поиске потенциальных партнёров и готовы рассмотреть любые предложения. Помощь не обязательно должна быть денежная, ведь помогать можно по-разному.</div>
         
         <br/><div>По вопросам сотрудничества пишите, пожалуйста, на почту  <a href="mailto:Sozvediyepegasa@mail.ru">Sozvediyepegasa@mail.ru</a> с пометкой "Сотрудничество" или звоните по телефону 
         &nbsp; <a href="tel:+79660121077">+7(966) 012-10-77</a>

        </div>
        <div className='Big_text Banner'> Волонтерство</div>
        <div className='Banner'>
          <img src={vol} alt="banner"></img></div>
        <p>Фонд не всегда может все делать своими руками, а помощь не обязательно должна быть финансовой.</p>
        <p> Мы постоянно нуждаемся в волонтерах и хотели бы видеть среди них и вас. </p><p>От скорой и квалифицированной помощи очень часто зависит многое.</p>

        </div>
);
}

export default Cooperation;