import React from 'react'
import './AboutUS.css';

function AboutUs() {
    return (
        <>
        <p className='aboutUsMain1'>
            <ul>
                <li>МИССИЯ: бескорыстная помощь нуждающимся и объединение неравнодушных людей, желающих оказать помощь.</li>
                <li>ЦЕЛЬ: предоставить социальную и психологическую помощь людям, попавшим в сложную жизненную ситуацию. Благотворительный фонд реализует несколько проектов, направленных на социальную поддержку населения.</li>
                <li>Благотворительный фонд "Созвездие пегаса" помогает детям-сиротам, детям из малообеспеченных семей, многодетным семьям, малоимущим, онкобольным детям, людям пожилого возраста.</li>
            </ul>
        
        ЗАДАЧИ ФОНДА: 
        <ul>
            <li>привлечение внимания общества к существующим проблемам детей-сирот, малоимущих и многодетных семей, онкобольных и пожилых людей;</li>
            <li>сбор, распределение и передача пожертвований непосредственно нуждающимся;</li>
            <li>поиск партнеров и создание доверительной среды, мотивирующей к благотворительной деятельности.</li>
            Благотворительный фонд «Созвездие Пегаса» основан в 2023 году.
            Организация объединяет усилия сразу многих людей и решает проблемы нуждающихся на качественном уровне. 
         </ul>
        </p>
        </>
);
}

export default AboutUs;