import React from 'react';
import Abdulaeva from './Abdulaeva.jpg';
import Nuzhdin from './Nuzhdin.jpg';

import './Team.css';



function Cooperation() {
    return (
        <div className='aboutUsMain2'>
            <div className='Big_text'>Команда фонда</div>
            <p>Познакомьтесь с командой фонда «Созвездие Пегаса»:</p><br/>
            <table>
            <tr><td>Директор БФ «Созвездие Пегаса» Нуждин Артем Геннадьевич </td> <td>Президент БФ «Созвездие Пегаса»: Абдуллаева Татьяна Федоровна </td></tr>
            <tr><td><img className='Photo' src={Nuzhdin} alt="Нуждин"></img> </td><td><img className='Photo' src={Abdulaeva} alt="Абдуллаева "></img></td></tr>
            <tr>
            <p>Попечительский совет:</p><br/>
            <p>Бачковская Нина Евгеньевна</p><br/>
            <p>Каюков Сергей Владимирович</p><br/></tr>
        </table>

        </div>
);
}

export default Cooperation;