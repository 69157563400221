import React from 'react'       
import Carousel from '../Slideshow/slideshow'


import './Main.css';
import first from './img/1.jpg';
import second from './img/2.jpg';
import third from './img/3.jpg';
import forth from './img/4.jpg';
import fifth from './img/5.jpg';
import sixth from './img/6.jpg';
import seventh from './img/7.jpg';
import eight from './img/8.jpg';

function Main() {
    return (
        
        <div className='MainSlide'>
  
<Carousel></Carousel>


<div className='RedLine2'></div>
<div className='FooterMain'>
    <div className='LeftMain'><div className='ContacsCaption'>Контакты:</div><br/>
    <div><span className='Grey'> Телефон: </span> <a href="tel:+79660121077">+7(966) 012-10-77</a></div>
    <div><span className='Grey'>Адрес: </span><div className='Contacs'>109147г. Москва ул. Марксистская ул. 7 помещение 5/1</div></div>
    <div><span className='Grey'>Наши мессенджеры:  </span><a href="https://t.me/ConstellationPegas">Telegram</a></div>
    <div><span className='Grey'>Соц. сети:  </span><a href="https://vk.com/constellationpegas">Вконтакте</a></div>
    <div><span className='Grey'>Почта: </span><a href="mailto:Sozvediyepegasa@mail.ru">Sozvediyepegasa@mail.ru</a></div>
    </div>
    <div className='RightMain'>
    <div>Созвездие Пегаса</div><br/>
    <div className='MainText'>Главной целью благотоврительного фонда является создание условий, при которых
    у каждого нуждающего имеется возможность получить помощь, а у каждого благотворителя есть 
    возможность её оказать.</div>   
    <div className='MainText'>Благотоврительный фонд - эффективное взаимодействие между получателями помощи и благотоврителями</div> 
    </div>
     
</div>
    
        </div>
  
);
}

export default Main;