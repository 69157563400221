import React from 'react'
import Navbar from '../Navbar/navbar';
import './Navigation.css';
import logo from './logo.png';
import line from './line.png';

function Navigation() {
    return (
        <div className='right'>
            <div className='TopNav'>
                <div className='TopNav2'>
                    <img alt='logo' className="Logo" src={logo}/>
                    <img alt='line' className="line" src={line}/>
                    <div className='TopCaption'>
                    <div className='Caption'>Созвездие </div>
                    <div className='Caption'>Пегаса</div></div> 
                </div>
              
                <div className='Nav'>
                    <Navbar></Navbar> 
                     
                </div>  
              
            </div>

        </div>
    );
  }

  export default Navigation;