import React from 'react'
import './NavBar.css';
import {NavLink} from 'react-router-dom';

function Navbar() {
    return (
        <ul className='menu'>
          <li key="1">
            <NavLink
                className="btn-menu"
                to="/"
                exact="true"
                activeClassName="btn-menu-active"
                >Главная</NavLink>
          </li>
          <li key="2">
            <NavLink
                className="btn-menu"
                to="/AboutUs"
                exact="true"
                activeClassName="btn-menu-active"
                >О&nbsp;фонде</NavLink>
          </li>
          <li key="3">
            <NavLink
             className="btn-menu"
                to="/Requisites"
                exact="false"
                activeClassName="active"
                >Реквизиты</NavLink>
          </li>
          <li key="4">
            <NavLink
             className="btn-menu"
                to="/Documents"
                exact="false"
                activeClassName="active"
                >Документы</NavLink>
          </li>
          <li key="5">
            <NavLink
             className="btn-menu"
                to="/Team"
                exact="false"
                activeClassName="active"
                >Команда&nbsp;фонда</NavLink>
          </li>
          <li key="6">
            <NavLink
             className="btn-menu"
                to="/Cooperation"
                exact="false"
                activeClassName="active"
                >Сотрудничество</NavLink>
          </li>   
           
        </ul>
  
);
}

export default Navbar;