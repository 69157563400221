import React from 'react'
import './Header.css';
import logo1 from './tele.png';
import logo2 from './VK.png';

function Header() {
    return (
      <div className="Top"> 
      <div className="Header">
        Благотворительный фонд
      </div>
      <div className="SocialNet">
       <a href="https://t.me/ConstellationPegas"><img alt='telegram' className="SocialNetpic" src={logo1}/></a>
       <a href="https://vk.com/constellationpegas"><img alt="vk" className="SocialNetpic1" src={logo2}></img></a>
      </div>
      </div>

    );
  }

  export default Header;