import React from 'react'
import './Documents.css';
import doc1 from './СВИДЕТЕЛЬСТВО.pdf';
import doc2 from './УСТАВ.pdf';

function Documents() {
    return (
        <div className='DocumentsMain'>
            <div className='Big_text'>Документы:</div>
            <div><a href={doc1} download>Свидетельство</a></div>
            <div><a href={doc2} download>Устав</a></div>
        </div>
);
}

export default Documents;