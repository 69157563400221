import React from 'react'
import './Requisites.css';
import qrcode from './qrcode.jpg';

function Requisites() {
    return (
        <div className='aboutUsMain3'>
           <div className='Big_text'> Реквизиты фонда </div>
           <p> Полное наименование Фонда на русском языке: Благотворительный фонд социальной помощи населению «Созвездие Пегаса»</p>
            <p>Сокращенное наименование Фонда на русском языке: БФ «Созвездие Пегаса»</p>
            <p>ИНН 9709098440,</p> <p>КПП 770901001,</p>  <p>ОГРН 1237700579951</p>
            <p  className='Big_text'>Адрес юридический:</p>
            <p>Реквизиты для перевода АО «АЛЬФА-БАНК»</p>
            <p>Банк: АО "АЛЬФА-БАНК"</p>
            <p>БИК: 044525593</p>
            <p>Получатель : БЛАГОТВОРИТЕЛЬНЫЙ ФОНД СОЦИАЛЬНОЙ ПОМОЩИ НАСЕЛЕНИЮ "СОЗВЕЗДИЕ ПЕГАСА"</p>
            <p>Счёта: 40703810202680000099</p>
            <p>ИНН: 9709098440</p>
            <p>КПП: 770901001</p>
            <p>Назначение платежа: Благотворительное пожертвование</p>
            <p>Адрес получателя: улица Марксистская, д. Д. 7, кв./оф. ПОМЕЩ. 5/1, г. Москва</p>
            <img src={qrcode} alt="QR code"></img> 
            <p>Нужно войти в приложение вашего банка и навести камеру на QR-код, после чего ввести желаемую сумму и подтвердить перевод</p>

        </div>
);
}

export default Requisites;